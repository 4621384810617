@import '@aurora/shared-client/styles/_variables.pcss';

@mixin gridColumns($size) {
  grid-template-columns: repeat(var(--lia-grid-num-$(size)), 1fr);
}

.lia-grid-wrap {
  container-type: inline-size;
  container-name: grid-wrap;
}

.lia-grid {
  display: grid;
  grid-auto-flow: dense;
  gap: var(--lia-grid-spacing);
}

@container grid-wrap (min-width: $lia-breakpoint-xs) {
  .lia-grid {
    @include gridColumns(xs);
  }
}

@container grid-wrap (min-width: $lia-breakpoint-sm) {
  .lia-grid {
    @include gridColumns(sm);
  }
}

@container grid-wrap (min-width: $lia-breakpoint-md) {
  .lia-grid {
    @include gridColumns(md);
  }
}

@container grid-wrap (min-width: $lia-breakpoint-lg) {
  .lia-grid {
    @include gridColumns(lg);
  }
}

@container grid-wrap (min-width:  $lia-breakpoint-xl) {
  .lia-grid {
    @include gridColumns(xl);
  }
}

.lia-flex {
  display: flex;
  flex-flow: row wrap;
  gap: var(--lia-grid-spacing);
}
